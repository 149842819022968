import WebComponent from '../webcomponent.js'

const template = (obj) => html`
<link rel="stylesheet" href="../../css/app.css">
<style>
  select.learners {
    height: 20em;
    min-width: 16em;
    border: 1px solid silver;
    border-radius: 5px;
    margin-right: 8px;
    flex-grow: 1;
  }

  ion-col:first-child {
    font-weight: bold;
  }

  div.expiry {
    background: #d08b0d;
    border-radius: 6px;
    padding: 6px;
    width: fit-content;
    color: white;
  }
</style>
<section>
  <h3><a href="#licenses">My Licences</a> &gt; Manage licences <span id="barlyOrgName"></span></h3>

  <main id="detailsHeader"> 
    <ion-grid style="background: #f9f9f9;">
      <ion-row>
        <ion-col>Organisation:</ion-col>
        <ion-col>
          <ion-button id="selectSchool" style="--background: gray; margin: 0;">
            <ion-label id="schoolName"></ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>Product:</ion-col>
        <ion-col>
          <ion-button id="selectProduct" style="--background: gray; margin: 0;">
            <ion-label id="productName"></ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row class="subsDetail">
        <ion-col>Unused Licences:</ion-col>
        <ion-col id="quantity"></ion-col>
      </ion-row>
      <ion-row class="subsDetail">
        <ion-col>Class:</ion-col>
        <ion-col>
          <div class="row" style="justify-content:flex-start">
            <select id="classes" name="classes" style="display:inline">
            </select>
            <!-- ion-button id="btnRefreshClassList" style="margin-left: 1em; --background: grey"
              title="Refresh class list"><ion-icon name="refresh" style="stroke:black"></ion-icon></ion-button -->
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <div id="jtButtons" class="buttonbar">
    </div>
      
    <div id="NoSubscriptionsFound" style="display:none">
        <ion-icon name="warning"></ion-icon> No subscriptions found for "${obj.getProductName()}".
    </div>

    <div id="notUserSpecified">
    </div>
    
    <div id="licenseList">
    </div>
  </main>
</section>

<div id="dialogs"></div>
`

const dialogs = (obj) => html`
<app-dialog id="dlgSelectThing" title="Select">
    <ion-searchbar id="searchThings"></ion-searchbar>
    <ion-list id="things" inset="true">
    </ion-list>
</app-dialog>

<app-dialog id="dlgNewLearner" title="Add new ${obj.l("learner")}">
  <form id="frmNewLearner">
    ${obj.theClass && obj.theClass.grade > 12 ? html`
      <ion-checkbox id="asEmployee" class="checkbox-label-placement-end" checked>Add as employee (default is learner)</ion-checkbox><br/>
      <ion-checkbox id="asLicenceAdmin" class="checkbox-label-placement-end">Employee is a licence administrator</ion-checkbox>
      `: ``}
    <ion-input name="fname" type="text" placeholder="First name" title="First name" pattern="[A-zÀ-ÿ ]+" required></ion-input>
    <ion-input name="sname" type="text" placeholder="Surname" title="Surname" pattern="[A-zÀ-ÿ ]+" required></ion-input>
    <ion-input name="studentNumber" type="text" placeholder="${obj.l("Student")} number"></ion-input>
    <label for="dob">Date of birth:</label>
    <ion-input type="date" id="newLearnerDob" name="dob" title="Date of birth" required></ion-input>
    <ion-checkbox class="checkbox-label-placement-end" id="addAnother" checked>Add another</ion-checkbox>
  </form>
</app-dialog>

<app-dialog id="dlgBuyLicence" title="Buy licence">
  Add licence to cart? You can adjust quantity within the cart.
</app-dialog>

<app-dialog id="dlgLeanerLogins" title="${obj.l("Learner")} logins and codes">
</app-dialog>

<app-dialog id="dlgProductAccess" title="Product access">
</app-dialog>

<app-dialog id="dlgAddClass" title="Add Class">
  <ion-input placeholder="Class name" title="Class name" id="className"></ion-input>
  <ion-range label="Grade" title="Grade" pin="true" ticks="true" snaps="true" min="0" max="18" id="grade">
    <span slot="start">0</span>
    <span slot="end">18</span>
  </ion-range>
</app-dialog>

<app-dialog id="dlgClassInvite" title="Invite to class">
  <p>
    Users can scan the QR Code or use the link to register for this class.
    <div id="inviteExpiryMessage"></div>
  </p>
  <app-qrcode id="qrcode"></app-qrcode>
</app-dialog>

<app-dialog id="dlgActivateCode" title="Optional activate code">
  <p>
    To restrict access to this product, you can set an optional activate code and optional start/end times.
  </p>
  <ion-grid>
    <ion-row class="align-items-center">
      <ion-col size="2"><label>Activate code</label></ion-col>
      <ion-col><ion-input id="activateCode" type="text" placeholder="Optional code to open / activate the licence" max-length="31" ></ion-input></ion-col>
    </ion-row>
    <ion-row class="align-items-center">
      <ion-col size="2"><label>Available from</label></ion-col>
      <ion-col><ion-input id="activateStart" type="datetime-local" placeholder="Licence available from" ></ion-input></ion-col>
    </ion-row>
    <ion-row class="align-items-center">
      <ion-col size="2"><label>Available until</label></ion-col>
      <ion-col><ion-input id="activateEnd" type="datetime-local" placeholder="Licence available till" ></ion-input></ion-col>
    </ion-row>
  </ion-grid>
</app-dialog>

`
const classButtons = (obj) => obj.theClass ? html`
  <ion-button id="btnClassInvite" title="Invite ${obj.l("learners")} to self-register to class">Invite</ion-button>
  <ion-button id="btnAddClass" title="Add a new class">Add class</ion-button>
  <ion-button id="btnClassLibrary" title="JumpTrak class library">Class Library &nbsp; <ion-icon name="open-outline"></ion-icon></ion-button>
` : ""

const notUserSpecified = (obj) => html`
  Any purchaser, staff member or ${obj.l("learner")} in the school will have access to the available licences for this product, up to a maximum of <span id="upToQuantity"></span> user(s).
  <p>
    <ion-button id="btnProductAccess" title="View details on how to access the product">Access Product</ion-button>
  </p>
`

const licenseLists = (obj) => html`
  <div class="row">
      <p style="width:32px">
        <ion-icon name="information-circle-outline" class="large-icon"></ion-icon> 
      </p>
      <p style="flex-grow: 1; margin-left: 1em">
        Click ${obj.l("learners")} in the lists below to assign/remove licences then click <i>Apply</i>.
        <br/>
        <ion-button id="btnDownloadAccessList" disabled title="View logins for ${obj.l("learners")} with a product licence">View logins</ion-button>
        <ion-button id="btnProductAccess" title="View details on how to access the product">Access Product</ion-button>
      </p>
  </div>

  <div class="row">
    <ion-searchbar id="nameSearch" placeholder="Search ${obj.l("learner")} name"></ion-searchbar>
    <div class="column">
      <b>${obj.l("Learners")} with licences:</b>
      <select id="licensed" class="learners" multiple 
        title="When you click Apply, this list of ${obj.l("learners")} will have licences">
      </select>
    </div>
    <div class="column">
      <b>${obj.l("Learners")} without licences:</b>
      <select id="unlicensed" class="learners" multiple 
        title="When you click Apply, this list of ${obj.l("learners")} will not have a licence">
      </select>
    </div>
  </div>
  
  <div class="buttonbar">
    <ion-button id="btnBuyMore" title="Buy additional licences">Buy licences</ion-button>
    <ion-button id="btnAuto" title="Allocate all available licences to ${obj.l("learners")} without">Auto allocate</ion-button>
    <ion-button id="btnAddSpares" title="Add up to 5 spare ${obj.l("learners")}">+5 Spares</ion-button>
    <ion-button id="btnAddLearner" title="Add a new ${obj.l("learner")}">Add ${obj.l("learner")}</ion-button>
    <ion-button id="btnApply" title="Apply changes">Apply</ion-button>
  </div>
`

const templateLogins = (obj, details, revealPasswords=false) => html`
  <span>NOTE: Only users with active licences will be shown here.</span><br/>
  <span style="display:flex;flex-direction:row">
      <ion-button id="btnDownloadLogins"><ion-icon name="download" slot="start"></ion-icon>Download CSV</ion-button>
      <ion-button id="btnReveal" style="display:${revealPasswords ? "none" : "block"}"><ion-icon name="eye" slot="start"></ion-icon>Show passwords</ion-button>
      <ion-button id="btnHide" style="display:${revealPasswords ? "block" : "none"}"><ion-icon name="eye" slot="start"></ion-icon>Hide passwords</ion-button>
  </span>
  <table id="tblLogins">
    <thead>
      <th>First name</th>
      <th>Surname</th>
      <th>Email</th>
      <th>User ID</th>
      <th>Password</th>
      <th>Activation code</th>
    </thead>
    <tbody>
      ${details.payload.filter(p => p.rights.length > 0).map(p => html`
        <tr>
          <td>${sanitize(p.firstName)}</td>
          <td>${sanitize(p.surname)}</td>
          <td>${sanitize(p.email)}</td>
          <td>${sanitize(p.userid)}</td>
          <td>${revealPasswords ? sanitize(p.password) : "*********"}</td>
          <td>${p.rights.length > 0 ? sanitize(p.rights[0].activationCode) : ""}</td>
        </tr>
      `).join("")}
    </tbody>
  </table>
`

const templateProductAccess = (obj) => html`
<style>
  img {
    min-width: 135px;
    height: 45px;
  }
</style>
You can access the product using the following:
<div>
  <p>
    Web application:<br/>
    <ion-button href="${obj.webLinkUrl}" target="_blank" style="--background: black; --background-hover-opacity: 0.3;">
      <ion-icon name="globe" class="medium-icon"></ion-icon>
      ${obj.webLinkText} &nbsp;
      <ion-icon name="open-outline"></ion-icon>
    </ion-button>
  </p>
  <p>
    ${obj.androidAppLink ?
    `<b>${obj.appName}</b> Android app:<br/>
    <a href="${obj.androidAppLink}" target="_blank" title="Android app"><img src="/images/google-play.svg"></a>` : ""}
  </p>
  <p>
    ${obj.iosAppLink ?
    `<b>${obj.appName}</b> iOS app:<br/>
    <a href="${obj.iosAppLink}" target="_blank" title="iOS app"><img src="/images/app-store.svg"></a>` : ""}
  </p>
</div>
`

customElements.define("page-manage-licenses", class extends WebComponent {
  async init() {
    this.orgRuid = app.getParams().get("org")
    this.productRuid = app.getParams().get("p")
    this.productName = app.getParams().get("c");
    let schoolRuid = app.getParams().get("s");
    //console.log("page-manage-licenses schoolRuid="+schoolRuid);
    this.capability = this.productName.split(":").pop()

    app.autohideProgress = false;
    let values = await Promise.all([
      app.api.orgList(),
      app.api.jtSchoolList(),
      app.api.getProductCapability(this.productRuid, this.capability, false),
      app.api.getSubscriptions(this.orgRuid)
    ])
    app.autohideProgress = true;
    app.progress.hide();
    this.orgs = values[0].organisations
    this.schools = values[1].schools
    this.product = values[2].product
    this.subscriptions = values[3].subscriptions

    this.changes = {
      "unlicense": [],
      "license": []
    }

    this.org = this.orgs.filter(o => o.ruid == this.orgRuid)[0]
    if (!this.org) throw new Error("Org not found: " + this.orgRuid);

    let school = this.schools.find(s => s.ruid === schoolRuid);

    if (!school) {
      school = this.schools.find(s => s.name.toLowerCase() == this.org.name.toLowerCase()
        && s.countryIsoCode.toLowerCase() == this.org.countryIsoCode.toLowerCase())
      if (!school) {
        if (this.schools.length==0)
          throw new Error("No JumpTrak schools accessible");
        school = this.schools[0];
      }
    }

    this.theSchool = school
    //console.log("page-manage-licenses school selected: "+JSON.stringify(school));

    await this.assignLicenses(this.orgRuid, this.productRuid, this.capability)
  }

  render() {
    this.shadow.innerHTML = template(this)

    this.detailsHeader = this.shadow.querySelector("#detailsHeader")
    this.detailsHeader.show = () => this.style.display = 'block';

    this.dialogs = this.shadow.querySelector("#dialogs")
    this.update()
  }

  update() {
    // update dialogs based on class details
    this.dialogs.innerHTML = dialogs(this)

    if (this.theSchool) {
        this.shadow.querySelector("#barlyOrgName").innerHTML = " for " + sanitize(this.org.name)
        this.detailsHeader.querySelector("#schoolName").innerHTML = sanitize(this.theSchool.name)
        this.detailsHeader.querySelector("#selectSchool").onclick = (ev) => {
            let dlg = this.shadow.querySelector("#dlgSelectThing");
            dlg.querySelector("#things").innerHTML = this.schools
                //.filter(s => this.orgs.filter(o => o.name === s.name).length > 0)
                .map(s =>
                    html`<ion-item class="btnSchoolSelect" button="true" 
                        schoolRuid="${s.ruid}">${s.name}</ion-item>`)
                .join("");
            let btns = dlg.querySelectorAll(".btnSchoolSelect");
            for (let btn of btns) {
                if (btn.innerText === this.theSchool.name) btn.disabled = true;
                btn.onclick = () => {
                    let sp = new URLSearchParams(location.href.split("?").pop())
                    location.href="#manage-licenses?org=" + this.orgRuid + "&p=" + sp.get("p") + "&c=" + sp.get("c") + "&s=" + btn.attributes['schoolRuid'].value
                }
            }
            this.showSearchDialog(dlg);
            return true;
        }
    } else {
        // jumptrak school not found
    }

    if (this.theSchool && this.theSchool.organisationTypeName &&
      this.theSchool.organisationTypeName.toLowerCase() == "jumptrak community") {
      // unit owners should not be adding classes or accessing class library
      this.shadow.querySelector("#jtButtons").innerHTML = ""
    } else {
      this.renderClassButtons(this)
    }

    if (!this.theSchool || !this.theClass) return;

    if (this.product) {
      this.detailsHeader.querySelector("#productName").innerHTML = this.productName

      if (!this.product.isUserSpecified) this.shadow.querySelector("#notUserSpecified").innerHTML = notUserSpecified(this);
      else this.renderLicenceLists(this);

      for (let btn of this.shadow.querySelectorAll("#btnProductAccess")) btn.onclick = () => {
        let dlg = this.shadow.querySelector("#dlgProductAccess")
        dlg.innerHTML = templateProductAccess(this.getAccessLinks())
        dlg.addEventListener('ok', () => dlg.close())
        dlg.show()
      }
    }

    if (this.btnClassInvite) {
      if (this.theClass.grade > 12) this.btnClassInvite.style.display = 'block';
      else this.btnClassInvite.style.display = 'none';
    }
  }

  renderClassButtons() {
    this.shadow.querySelector("#jtButtons").innerHTML = classButtons(this)
    this.btnClassInvite = this.shadow.querySelector("#btnClassInvite")
    this.dlgAddClass = this.shadow.querySelector("#dlgAddClass")
    this.btnClassLibrary = this.shadow.querySelector("#btnClassLibrary")
    if (!this.btnClassLibrary) return;

    this.btnClassLibrary.addEventListener('click', () => {
      if (this.theClass && this.theClass.id) this.goClassLibrary(this.theClass)
    })

    //this.btnRefreshClassList = this.shadow.querySelector("#btnRefreshClassList")
    //this.btnRefreshClassList.style.display = "none" // hide until "add class" is clicked
    //this.btnRefreshClassList.addEventListener("click", () => {
    //  this.loadClassList()
    //})

    this.shadow.querySelector("#btnAddClass").addEventListener('click', () => {
      if (this.btnRefreshClassList) this.btnRefreshClassList.style.display = "block"
      if (this.theSchool && this.theSchool.id) this.goAddClass(this.theSchool)
    })

    if (this.btnClassInvite) this.btnClassInvite.addEventListener('click', async () => {
      if (this.theClass && this.theClass.grade > 12) {
        this.btnClassInvite.disabled = true
        // get an invite code
        let code = await app.api.jtClassInviteCode(this.theClass.ruid)
        this.shadow.querySelector("#qrcode").setUrl(app.api.getEnv().BARLY_URL + "/register?code=" + code.code)
        if (code.expiryDateTime) {
          this.shadow.querySelector("#inviteExpiryMessage").innerHTML = html`
            <div class="expiry">Link expires: ${this.dateFormat(code.expiryDateTime)}</div>
          `
        }
        let dlg = this.shadow.querySelector("#dlgClassInvite")
        dlg.addEventListener('ok', () => dlg.close())
        dlg.show()
        this.btnClassInvite.disabled = false
      }
    })
  }

  renderLicenceLists() {
    this.shadow.querySelector("#licenseList").innerHTML = licenseLists(this);

    this.dlgNewLearner = this.shadow.querySelector("#dlgNewLearner")
    this.unlicensed = this.shadow.querySelector("#unlicensed")
    this.licensed = this.shadow.querySelector("#licensed")

    this.btnApply = this.shadow.querySelector("#btnApply")
    this.btnApply.disabled = true
    this.btnApply.addEventListener('click', () => {
      this.btnApply.disabled = true
      this.apply()
    })

    this.licensed.addEventListener('click', () => {
      let sel = this.licensed;
      if (sel.selectedIndex < 0) return;
      let opt = sel.options[sel.selectedIndex]
      if (opt.attributes['licenses'] && Number(opt.attributes['licenses'].value) > 1) {
        // if user has more than 1 license, free up the extra licenses
        let numberReleased = Number(opt.attributes['licenses'].value) - 1
        this.addChange(opt.value, numberReleased, false, opt.attributes['licenses'].value > 0)
        opt.attributes['licenses'].value = 1
        // update the license indicator
        opt.label = "📜 " + opt.label.replaceAll("📜", "")
        app.toast("Freed up " + numberReleased + " licence(s), click user again to remove remaining licence.")
      } else {
        sel.remove(sel.selectedIndex)
        this.unlicensed.add(opt)
        this.addChange(opt.value, 1, false, this.hasLicense(opt))
      }
      this.licensed.selectedIndex = -1
      this.unlicensed.selectedIndex = -1
    })

    this.unlicensed.addEventListener('click', () => {
      if (!this.checkLicences()) return;
      let sel = this.unlicensed;
      if (sel.selectedIndex < 0) return;
      let opt = sel.options[sel.selectedIndex]
      sel.remove(sel.selectedIndex)
      this.licensed.add(opt)
      this.licensed.selectedIndex = -1
      this.unlicensed.selectedIndex = -1
      this.addChange(opt.value, 1, true, this.hasLicense(opt))
    })

    this.btnAddLearner = this.shadow.querySelector("#btnAddLearner")
    this.btnAddLearner.addEventListener('click', () => {
      let asEmployee = this.dlgNewLearner.querySelector("#asEmployee")
      let asLicenceAdmin = this.dlgNewLearner.querySelector("#asLicenceAdmin")
      if (asEmployee) {
        if (this.theClass.grade > 12) asEmployee.checked = true;
        else asEmployee.checked = false;
        if (asLicenceAdmin) {
          asLicenceAdmin.checked = false;
          asLicenceAdmin.disabled = false;
        }
        asEmployee.onclick=() => {
          if (asEmployee.checked) asLicenceAdmin.disabled=false;
          else asLicenceAdmin.disabled=true
        }
      }
      this.dlgNewLearner.show()
    })

    this.btnAddSpares = this.shadow.querySelector("#btnAddSpares")
    this.btnAddSpares.addEventListener('click', async () => {
      let quantity = Math.min(5, this.availableLicenses - this.unlicensed.options.length)
      if (quantity <= 0) {
          throw new Error("No more available licences found.")
      }
      let ret = await app.api.apxAddSpares(this.orgRuid, "Learners", "pupil",
        this.theClass.id, quantity)

      if (!ret.success) {
        alert(ret.messages.map(m => m.message).join("\n"))
        return;
      }

      for (let spare of ret.spares) {
        this.showNewLearner(spare.firstName, spare.surname, spare.personRuid)
      }
    })

    this.shadow.querySelector("#btnBuyMore").addEventListener('click', async () => {
      let dlg = this.shadow.querySelector("#dlgBuyLicence")
      dlg.addEventListener('ok', async () => {
        dlg.hide()
        let productCapability = await app.api.getProductCapability(this.product.identifier, this.capability, false)
        if (productCapability.isSellable) {
          let productPrice = await app.api.getProductCapabilityPrices([productCapability.product.id], 1, this.orgRuid, app.currency.currencyCode())
          //app.setBuyOrganisation(this.orgRuid)
          app.cart.addProduct(productPrice.productCapabilities[0], 1)
          app.loadPage("cart")
        } else {
          throw new Error("This product is not currently available for purchase via the store - please contact JumpTrak")
        }
      })
      dlg.show()
    })

    this.shadow.querySelector("#newLearnerDob").max = new Date().toISOString().substring(0, 10)
    this.dlgNewLearner.onOk = async () => {
      let form = this.shadow.querySelector("#frmNewLearner")
      let asEmployeeCheckbox = this.shadow.querySelector("#asEmployee")
      let asLicenceAdminCheckbox = this.dlgNewLearner.querySelector("#asLicenceAdmin")
      let asEmployee = asEmployeeCheckbox && asEmployeeCheckbox.checked
      let asLicenceAdmin = asLicenceAdminCheckbox && asLicenceAdminCheckbox.checked
      if (!form.reportValidity()) return;
      if (!this.checkLearner(form)) return;

      let orgRoles = [{
          "forOrganisationRuid": this.orgRuid,
          "organisationName": (asEmployee ? "Staff" : "Learners"),
          "orgRoleTypeName": (asEmployee ? "employee" : "pupil"),
          "number": form.studentNumber.value,
          "hasOrgRoleTypeRuid": ""
      }]

      if (asEmployee || asLicenceAdmin) {
        // add user admin for employees
        orgRoles.push({
          "forOrganisationRuid": this.orgRuid,
          "organisationName": "Learners",
          "orgRoleTypeName": "user admin (own org)",
          "number": form.studentNumber.value,
          "hasOrgRoleTypeRuid": ""
        })
      }

      if (asLicenceAdmin) {
        orgRoles.push({
          "forOrganisationRuid": this.orgRuid,
          "organisationName": "",
          "orgRoleTypeName": "licence admin (own and descendants)",
          "number": form.studentNumber.value,
          "hasOrgRoleTypeRuid": ""
        })
      }

      let learnerDetails = {
        "firstName": form.fname.value,
        "surname": form.sname.value,
        "dateOfBirth": form.dob.value.replaceAll("-", "/"),
        "idNumbers": [],
        "calledName": "",
        "row": 0,
        "userid": "",
        "externalOrganisationRoles": orgRoles,
        "id": 0,
        "email": "",
        "gender": "",
        "language": ""
      }

      let ret = await app.api.apxLearnerAdd(this.theClass.id, learnerDetails)
      if (!ret.success) {
        alert(ret.messages.map(m => m.message).join("\n"))
        return;
      }

      this.showNewLearner(form.fname.value, form.sname.value, ret.learnerCapture.personRuid)
      if (!this.shadow.querySelector("#addAnother").checked) {
        this.dlgNewLearner.hide()
      }
      form.reset()
    }

    this.btnAuto = this.shadow.querySelector("#btnAuto")
    this.btnAuto.addEventListener('click', async () => {
      let currentlyAvailable = this.availableLicenses
      for (let i = 0; i < currentlyAvailable; i++) {
        if (this.unlicensed.length > 0) {
          let opt = this.unlicensed.options[0]
          this.licensed.add(opt)
          this.addChange(opt.value, 1, true, this.hasLicense(opt))
        } else {
          break;
        }
      }
      this.licensed.selectedIndex = -1
      this.unlicensed.selectedIndex = -1
    })

    this.shadow.querySelector("#nameSearch").addEventListener('keyup', (e) => {
      let keywords = e.target.value.toLowerCase();
      for (let o of this.licensed.options) {
        if (o.label.toLowerCase().indexOf(keywords) < 0) {
          o.style.display = 'none';
        } else {
          o.style.display = 'block';
        }
      }

      for (let o of this.unlicensed.options) {
        if (o.label.toLowerCase().indexOf(keywords) < 0) {
          o.style.display = 'none';
        } else {
          o.style.display = 'block';
        }
      }
    })

    this.shadow.querySelector("#nameSearch").addEventListener('ionClear', (e) => {
      for (let o of this.licensed.options) {
        o.style.display = 'block';
      }

      for (let o of this.unlicensed.options) {
        o.style.display = 'block';
      }
    })
  }

  showNewLearner(firstName, surname, personRuid) {
    let newOpt = new Option(firstName + " " + surname, personRuid)
    if (this.availableLicenses > 0) {
      this.licensed.add(newOpt)
      this.licensed.selectedIndex = -1
      this.addChange(newOpt.value, 1, true, false)
    } else {
      this.unlicensed.add(newOpt)
      this.unlicensed.selectedIndex = -1
    }
  }

  hasLicense(option) {
    if (option.attributes['licenses'] && option.attributes['licenses'].value > 0) return true;
    else return false;
  }

  addChange(ruid, quantity, isLicense, hasLicense) {
    // remove any existing changes
    this.changes.license = this.changes.license.filter(i => i.ruid != ruid)
    this.changes.unlicense = this.changes.unlicense.filter(i => i.ruid != ruid)

    if (isLicense) {
      if (!hasLicense) this.changes.license.push({ ruid, quantity })
      this.availableLicenses -= quantity
    } else {
      if (hasLicense) this.changes.unlicense.push({ ruid, quantity })
      this.availableLicenses += quantity
    }

    this.btnApply.disabled = this.changes.license.length == 0 && this.changes.unlicense.length == 0
    this.renderQuantity()
  }

  async apply() {
    if (this.changes.license.length == 0 && this.changes.unlicense.length == 0) {
      this.btnApply.disabled = true
      return;
    }

    let dlg = this.shadow.querySelector("#dlgActivateCode")
    if (dlg && this.changes.license.length > 0) {
      dlg.onOk = () => {
        const toDateTimeString = (v) => v ? v.replaceAll("-", "").replaceAll("T", "").replaceAll(":", "") : v
        let code = dlg.querySelector("#activateCode").value
        let start = toDateTimeString(dlg.querySelector("#activateStart").value)
        let end = toDateTimeString(dlg.querySelector("#activateEnd").value)
        this.applyLicenceChanges(code, start, end)
        dlg.hide()
      }
      dlg.show()
    } else {
      this.applyLicenceChanges("", "", "")
    }
  }

  async applyLicenceChanges(activateCode, activateStart, activateEnd) {
    try {
      let ret = await app.api.apxLicense(
        this.orgRuid,
        this.productName,
        this.changes.license,
        this.changes.unlicense,
        this.theSchool.countryIsoCode,
        this.theSchool.name,
        this.theClass.name,
        activateCode,
        activateStart,
        activateEnd)
      // reload subscriptions
      this.subscriptions = (await app.api.getSubscriptions(this.orgRuid)).subscriptions
    } finally {
      this.changes = {
        "unlicense": [],
        "license": []
      }
      await this.assignLicenses(this.orgRuid, this.productRuid, this.capability)
      this.btnApply.disabled = true
    }
  }

  renderQuantity() {
    this.detailsHeader.querySelector("#quantity").innerHTML = this.availableLicenses
  }

  async addLearners(theClass, productName, quantity, subscriptions) {
    this.update()
    let year = new Date().getYear() + 1900;
    let learners = await app.api.jtLearnerList(theClass.id, year)

    let usageRights = subscriptions.flatMap(s =>
      s.usageRights.filter(r => r.name == productName)
        .map(u => { u.subscription = s; return u })
        .shift())

    let namedUsers = usageRights.flatMap(u => {
      // save a reference to the usage right in the named user
      u.namedUserRights.map(nur => nur.usageRight = u)
      return u.namedUserRights
    })

    const licenceExpiry = (namedUsers) => {
      const soonest = (date1, date2) => {
        if (!date1) return date2;
        if (!date2) return date1;
        if (Number(date1) < Number(date2)) return date1;
        else return date2;
      }

      let endDate = namedUsers
        .map(n => soonest(n.endDate,
          soonest(n.usageRight.enddate, n.usageRight.subscription.endDate)))
        .reduce((p, c) => soonest(p, c))

      if (endDate) {
        return " ⏳ " + this.expiryDate(endDate)
      } else {
        return ""
      }
    }

    this.availableLicenses = Number(quantity)
    this.renderQuantity()

    if (usageRights[0].productIsUserSpecified == false) {
      this.shadow.querySelector("#licenseList").style.display = "none";
      this.shadow.querySelector("#notUserSpecified").style.display = "block";
      this.shadow.querySelector("#upToQuantity").innerHTML = this.availableLicenses;

      if (usageRights[0].endDate && usageRights[0].endDate != "") {
        this.shadow.querySelector("#notUserSpecified").innerHTML += " This licence expires " + this.expiryDate(usageRights[0].endDate)
      }
    } else {
      this.btnLogins = this.shadow.querySelector("#btnDownloadAccessList")
      this.btnLogins.onclick = () => {
        this.btnLogins.disabled = true
        this.getLogins(theClass, subscriptions)
      }
      this.btnLogins.disabled = false

      // check if there are (enough) learners for this class
      let unlicensed = ""
      let licensed = ""
      const showLicenses = (l) => l > 0 ? "📜".repeat(l) + " " : ""
      for (let l of learners.learners) {
        let licenses = namedUsers.reduce((p, c) => (c.forPersonRuid == l.personRuid ? 1 : 0) + p, 0)
        if (licenses > 0) {
          licensed += "<option value='" + l.personRuid + "' licenses=" + licenses + ">" + showLicenses(licenses) + l.firstName + " " + l.surname + licenceExpiry(namedUsers.filter(n => n.forPersonRuid == l.personRuid)) + "</option>"
        } else {
          unlicensed += "<option value='" + l.personRuid + "'>" + l.firstName + " " + l.surname + "</option>"
        }
      }

      this.unlicensed.innerHTML = unlicensed
      this.licensed.innerHTML = licensed
    }

    this.detailsHeader.addEventListener('ok', () => {
      this.detailsHeader.hide()
      let learners = []
      for (let o of this.licensed) {
        if (o.learner) {
          learners.push(o.learner)
        } else {
          learners.push({
            "personRuid": o.value
          })
        }
      }

      let nllearners = []
      for (let o of this.unlicensed) {
        if (o.learner) {
          nllearners.push(o.learner)
        } else {
          nllearners.push({
            "personRuid": o.value
          })
        }
      }
    })
    this.detailsHeader.show()
  }

  expiryDate(endDate) {
    if (!endDate || endDate == "") return undefined;
    else {
      let dateString = endDate.substring(0, 4) + "/" + endDate.substring(4, 6) + "/" + endDate.substring(6, 8)
      return dateString
      //let timeString = endDate.substring(8, 10) + ":" + endDate.substring(10, 12) + ":" + endDate.substring(12, 14)
      //return new Date(dateString + " " + timeString)
    }
  }

  goAddClass(theSchool) {
    this.dlgAddClass.onOk = async () => {
      let className = this.dlgAddClass.querySelector("#className").value
      let grade = this.dlgAddClass.querySelector("#grade").value
      let ret = await app.api.apxClassAdd(className, theSchool.ruid, grade)
      if (ret.class && ret.class.id) {
        let sel = this.shadow.querySelector("#classes")
        let option = new Option(sanitize(ret.class.name), ret.class.id)
        sel.classes.push(ret.class)
        sel.options.add(option)
      }
      this.dlgAddClass.hide()
    }
    this.dlgAddClass.show()
  }

  async goClassLibrary(theClass) {
    let year = new Date().getYear() + 1900;

    window.open(app.api.getEnv().JUMPTRAK_URL +
      "/learner/purchase?forClass=" + theClass.id +
      "&forAssessment=0&academicYear=" + year)
  }

  async getLogins(theClass, subscriptions, revealPasswords=false, cachedResults) {
    let year = new Date().getYear() + 1900;
    let res = cachedResults ? cachedResults : await app.api.getLicenceDetails(theClass.id, year, subscriptions.map(s => s.id))
    let dlg = this.shadow.querySelector("#dlgLeanerLogins")
    dlg.innerHTML = templateLogins(this, res.response, revealPasswords)
    let btnReveal = dlg.querySelector("#btnReveal")
    let btnHide = dlg.querySelector("#btnHide")

    btnReveal.addEventListener('click', (ev) => {
        this.getLogins(theClass, subscriptions, true, res);
    });

    btnHide.addEventListener('click', (ev) => {
        this.getLogins(theClass, subscriptions, false, res);
    });

    dlg.querySelector("#btnDownloadLogins").addEventListener('click', () => {
      // sanitize/escape CSV values
      const s = (value) => {
        if (!value || value.indexOf(",") < 0) {
          return value
        } else {
          return "\"" + value.replaceAll("\"", "\"\"") + "\""
        }
      }
      // Generate CSV rows
      const rows = res.response.payload.filter(r => r.rights && r.rights.length > 0).map(r => {
        return [
          s(r.firstName), s(r.surname), s(r.email), s(r.userid), s(r.password),
          s(r.rights.map(ur => ur.activationCode).join(","))
        ]
      })
      // Add header
      const csvContent = "First name, Surname, Email, User ID, Password, Activation Code\n" + rows
        .map((e) => e.join(","))
        .join("\n");
      // Force browser to download the CSV blob
      const blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8;'
      });
      const date = (new Date()).toISOString().substr(0, 10)
      const filename = (this.theClass && this.product ?
        this.theClass.name + "_" + this.product.name + "_userids_" + date + ".csv" :
        "userids_" + date + ".csv"
      )

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click()

    })
    dlg.addEventListener('ok', () => dlg.close())
    dlg.show()
    this.btnLogins.disabled = false;
  }

  removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  async assignLicenses(orgId, productRuid, capability) {
    let subs = this.subscriptions.filter(s => {
      // filter out the subscriptions that contain the product we are interested in
      let usageRights = s.usageRights.filter(u => u.productIdentifier == productRuid && u.capabilityCode == capability)
      return usageRights.length > 0
    })

    // allow selecting other products
    this.detailsHeader.querySelector("#selectProduct").onclick = (ev) => {
        let dlg = this.shadow.querySelector("#dlgSelectThing");
        let usageRights = this.subscriptions
            .flatMap(s => s.usageRights)
            .filter(r => r.isSellable)
        let displayedProducts = {} // map used to remove duplicate products
        dlg.querySelector("#things").innerHTML = usageRights
            .sort((a, b) => a.name > b.name ? 1 : -1)
            .map(p => {
                if (displayedProducts[p.name]) {
                    return "" // this product already displayed
                } else {
                    displayedProducts[p.name] = 1;
                    return html`<ion-item class="btnProductSelect" button="true" 
                        p="${p.productIdentifier}" c="${p.name}">${p.name}</ion-item>`
                }
            })
            .join("");
        let btns = dlg.querySelectorAll(".btnProductSelect");
        for (let btn of btns) {
            if (btn.innerText === this.product.name) btn.disabled = true;
            btn.onclick = () => {
                let sp = new URLSearchParams(location.href.split("?").pop())
                location.href="#manage-licenses?org=" + sp.get("org") + "&p=" + btn.attributes['p'].value +
                    "&c=" + btn.attributes['c'].value + "&s=" + sp.get("s")
            }
        }
        this.showSearchDialog(dlg);
        return true;
    }

    if (!subs.length) {
        this.noSubscriptionsFound()
        return;
    }

    let products = {}
    for (let s of subs) {
      for (let r of s.usageRights) {
        if (!products[r.name]) {
          products[r.name] = {
            name: r.name,
            capabilityCode: r.capabilityCode,
            identifier: r.productIdentifier,
            isUserSpecified: r.productIsUserSpecified,
            isSellable: r.isSellable,
            quantity: r.quantity,
            quantityInUse: r.quantityInUse,
            subscriptions: [s]
          }
        } else {
          products[r.name].quantity += r.quantity;
          products[r.name].quantityInUse += r.quantityInUse;
          products[r.name].subscriptions.push(s)
        }
      }
    }

    let ret = []
    for (let p in products) {
      ret.push(products[p])
    }

    let product = ret.filter((p) => p['identifier'] == productRuid)[0];
    // copy in subscription details into product
    this.product = {
        ...this.product,
        ...product
    }

    // update productName
    this.productName = product.name
    this.quantity = product.isUserSpecified ? product.quantity - product.quantityInUse : product.quantity;

    if (this.theSchool) {
      this.loadClassList()
    } else {
      // no jumptrak school found, so add licenses in Barly
      alert("Unable to find school in JumpTrak. Please contact support quoting organisation id " + orgId);
    }
  }

  getProductName() {
    return this.productName;
  }

  async loadClassList() {
    let sel = this.shadow.querySelector("#classes")
    let product = this.product
    let quantity = this.quantity
    if (!sel.classes) {
      let classes = (await app.api.jtClassList(this.theSchool.ruid)).classes
      sel.classes = classes
    }

    if (sel.classes.length >= 1) {
      // ask user to pick a sub-org
      this.removeOptions(sel)
      for (let so of sel.classes) {
        let opt = new Option(sanitize(so.name), so.id)
        sel.options.add(opt)
      }
      if (this.selectedClassIndex) sel.selectedIndex = this.selectedClassIndex;

      sel.onchange = () => {
        this.selectedClassIndex = sel.selectedIndex
        this.theClass = sel.classes.filter((c) => c.id == sel.options[sel.selectedIndex].value)[0]
        // reset changes
        this.changes = {
          "unlicense": [],
          "license": []
        }

        this.addLearners(this.theClass, product.name, quantity, product.subscriptions)
      }

      requestAnimationFrame(() => {
        sel.dispatchEvent(new Event('change'))
      })
      this.update()
    } else {
      alert('No classes found for school in JumpTrak, please add a class.');
      this.update()
      return;
    }
  }

  checkLicences() {
    if (this.availableLicenses <= 0) {
      alert('No more licenses available');
      return false;
    }
    return true;
  }

  _checkLearnerExists(learner, form) {
    if (learner &&
      learner.firstName.trim().toLowerCase() == form.fname.value.trim().toLowerCase() &&
      learner.surname.trim().toLowerCase() == form.sname.value.trim().toLowerCase() &&
      learner.dob == form.dob.value) {
      alert(l("Learner") + ' already added')
      return false;
    }

    return true
  }

  checkLearner(form) {
    for (let o of this.unlicensed.options) {
      if (!this._checkLearnerExists(o.learner, form)) return false
    }

    for (let o of this.licensed.options) {
      if (!this._checkLearnerExists(o.learner, form)) return false
    }

    return true
  }

  getAccessLinks() {
    if (this.product) {
      let identifier = this.product.identifier
      let capabilityCode = this.product.capabilityCode

      if (this.product.productTypeName == "NumberSense Workbook") {
        return {
          webLinkText: "NumberSense Teacher Portal",
          webLinkUrl: app.api.getEnv().JUMPTRAK_URL + "/ns/login",
          appName: "NumberSense",
          androidAppLink: "https://play.google.com/store/apps/details?id=com.jumpco.numbersense",
          iosAppLink: "https://apps.apple.com/us/app/numbersense-app/id1196173011"
        }
      } else if (identifier.toLowerCase() == "jumptrak.peer.marker" && capabilityCode == "use") {
        return {
          webLinkText: "Peer Marker",
          webLinkUrl: app.api.getEnv().BARLY_URL + "/login?app=peer"
        }
      } else if ((identifier.toLowerCase() == "jumptrak.quiz.wizard" && capabilityCode == "use") ||
          (identifier.toLowerCase() == "jumptrak.library.author" && capabilityCode == "use")) {
        return {
          webLinkText: "JumpTrak Studio",
          webLinkUrl: app.api.getEnv().BARLY_URL + "/login?app=studio"
        }
      } else {
        return {
          webLinkText: "JumpTrak Library",
          webLinkUrl: app.api.getEnv().BARLY_URL + "/login?app=library",
          appName: "JumpTrak",
          androidAppLink: "https://play.google.com/store/apps/details?id=com.jumpcodigital",
          iosAppLink: "https://apps.apple.com/us/app/jumptrak/id1279264613"
        }
      }
    } else {
      return {
        webLinkText: "JumpTrak Library",
        webLinkUrl: app.api.getEnv().BARLY_URL + "/login?app=library",
        appName: "JumpTrak",
        androidAppLink: "https://play.google.com/store/apps/details?id=com.jumpcodigital",
        iosAppLink: "https://apps.apple.com/us/app/jumptrak/id1279264613"
      }
    }
  }

  showSearchDialog(dlg) {
    let search = dlg.querySelector("#searchThings")
    search.value = ""
    search.addEventListener('keyup', () => {
        let items = dlg.querySelectorAll("ion-item")
        let searchText = search.value.toLowerCase()
        for (let item of items) {
            if (searchText == "") {
                item.style.display = "block";
            } else if (item.innerText.toLowerCase().indexOf(searchText) < 0) {
                item.style.display = "none";
            } else {
                item.style.display = "block";
            }
        }
    })
    search.setFocus(true)
    dlg.addEventListener('ok', () => dlg.close());
    dlg.show()
  }

  noSubscriptionsFound() {
    this.update()
    this.shadow.querySelector("#NoSubscriptionsFound").style.display = "block";
    this.shadow.querySelector("#productName").innerHTML = this.getProductName();
    // hide rows in the detail header that are now blank
    let elems = this.detailsHeader.querySelectorAll(".subsDetail")
    for (let e of elems) {
        e.style.display = 'none';
    }
  }

  dateFormat(datetime) {
    let d = datetime.match("(.{4})(.{2})(.{2})(.{2})(.{2})(.{2})(.{0,3})")
    return `${d[1]}-${d[2]}-${d[3]} ${d[4]}:${d[5]}:${d[6]}`
  }

  // Show "Learner" as "User" if class grade > 12
  l(learner) {
    if (!this.theClass || this.theClass.grade <= 12) {
      return learner
    } else {
      return learner.replace("learner", "user")
        .replace("Learner", "User")
        .replace("student", "employee")
        .replace("Student", "Employee")
    }
  }
})
