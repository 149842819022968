import WebComponent from '../webcomponent.js'
import '../components/app-dialog.js'
import '../components/buy-organisations.js'
import "../components/login-banner.js"
import "../components/app-qrcode.js"

const template = () => html`
<link rel="stylesheet" href="/css/app.css">
<style>
  td.quantity {
    display: flex;
    justify-content: space-around;
  }

  #buy_actions {
    display: flex;
    flex-direction: column;
  }
</style>
<section id="cart">
  <header>
    <h1>Shopping Cart</h1>
  </header>
  
  <table align="center">
    <thead>
      <th>Product</th>
      <th>Quantity</th>
      <th>Unit Price</th>
      <th>Total Price</th>
    </thead>
    <tbody id="cart_rows">
      ${cartLoading()}
    </tbody>
    <tfoot>
      <tr>
          <th align="left" colspan="3">TOTAL incl. VAT:</th>
          <th id="cart_total_price" align="right"></th>
      </tr>
    </tfoot>
  </table>
</section>

<div id="couponContainer" style="z-index:0">
</div>

<div>
  <p style="float:left;margin-right: 4em;">
    <ion-button id="btnContinueShopping">Continue shopping</ion-button>
  </p>
  <p id="login" style="float:right;margin-left: 2em;">
    <ion-button id="btnLogin">Login/register to checkout</ion-button>
  </p>
  <p id="buy_actions" style="float:right;margin-left: 2em;">
    <buy-organisations label="Buying for"></buy-organisations>
    <span style="display:flex; flex-direction:row; margin: 6px 0;">
      <input type="checkbox" id="buyForMe" name="buyForMe"></input> 
      &nbsp;
      <label for="buyForMe">Assign a licence to me</label>
    </span>
  <ion-button id="checkoutButton">Checkout</ion-button>
  </p>
</div>

<app-dialog id="dlgNewOrg" title="New organisation">
  <form name="frmNewOrg" id="frmNewOrg" style="border: none;box-shadow: none;">
    <b>Organisation Type:</b><br/>
    <select name="newOrgType" id="newOrgType" required>
      <option>College</option>
      <option>Company</option>
      <option selected>School</option>
      <option>University</option>
    </select>
    <b>Country:</b><br/>
    <select name="country" id="newOrgCountry">
    </select>
    <b>Province:</b><br/>
    <select name="province" id="newOrgProvince">
    </select>
    <ion-input id="newOrgName" placeholder="New organisation name" required></ion-input>
    <ion-input id="newOrgReg" placeholder="Registration number" required></ion-input>
    <ion-input id="newOrgTax" placeholder="Tax number"></ion-input>
  </form>
</app-dialog>

<app-dialog id="dlgMyUnit" title="Self-purchase details">
  <form name="frmNewOrg" id="frmNewOrg" style="border: none;box-shadow: none;">
    <b>Country:</b><br/>
    <select name="country" id="newOrgCountry">
    </select>
    <b>Province:</b><br/>
    <select name="province" id="newOrgProvince">
    </select>
  </form>
</app-dialog>
`

const couponRedeem = (obj) => html`
  <p class="row">
    <ion-input id="couponCode" style="width:20em" label="Apply Coupon:" placeholder="Coupon code">
     <ion-button slot="end"><ion-icon name="chevron-forward-outline"></ion-icon></ion-button>
    </ion-input>
  </p>
`

const cartLoading = (obj) => html`
      <tr>
        <td><ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text></td>
        <td><ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text></td>
        <td><ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text></td>
        <td><ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text></td>
      </tr>
`

const cartRow = (obj, p) => html`
  <tr class="product" product_id="${p.productIdentifier}" is_trial="${p.isTrial}">
    <td class="productDescription">${p.description}${p.isTrial ? `<ion-chip>${p.allowedTempDays} day trial</ion-chip>` : ""}</td>
    <td class="quantity">
      <input type="number" ${p.isTrial ? "disabled" : ""} id="${p.id}" name="quantity" value="${p.quantity ? p.quantity : 1}" size="5" min="1" max="1000"/>
      <ion-button class="delete" title="Remove from cart" id="${p.id}" fill="clear"><ion-icon slot="icon-only" name="close-circle"></ion-icon></ion-button>
    </td>
    <td align="right"><input type="hidden" name="price" value="${p.price.ruid}" amount="${p.price.amount}"> ${obj.priceFormatted(p.price.amount)} </td>
    <td align="right" id="line_total">${obj.priceFormatted(p.price.amount * p.quantity)} </td>
  </tr>
`

const couponRow = (obj, coupon) => html`
<tr>
  <td colspan="2">
    <div style="display:flex; flex-direction: row; align-items: baseline">
      <span class="coupon" ${coupon.applicable ? "valid" : "invalid"}>Coupon ${coupon.code}</span>
      ${coupon.message}
    </div>
    <input type="hidden" name="price" value="${coupon.code}" amount="${coupon.price}">
    <input type="hidden" name="quantity" value="-1">
  </td>
  <td>
    <ion-button class="delete" title="Remove coupon" couponCode="${coupon.code}" fill="clear"><ion-icon slot="icon-only" name="close-circle"></ion-icon></ion-button>
  </td>
  <td align="right" id="line_total"></td>
</tr>
`

customElements.define("page-cart", class extends WebComponent {
  render() {
    this.shadow.innerHTML = template()
    this.dlgNewOrg = this.shadow.querySelector("#dlgNewOrg")
    this.dlgMyUnit = this.shadow.querySelector("#dlgMyUnit")
    this.isForMe = this.shadow.querySelector("#buyForMe")

    this.buyOrganisation = this.shadow.querySelector("buy-organisations")
    this.buyOrganisation.addEventListener('change', () => {
      // re-load the cart with prices for the selected organisation
      this.btnCheckout.disabled = true
      this.update()
    })

    this.btnCheckout = this.shadow.querySelector("#checkoutButton")
    this.btnCheckout.addEventListener('click', async () => {
      this.btnCheckout.disabled = true
      await this.checkout(this.buyOrganisation.value)
      this.btnCheckout.disabled = false
    })

    this.shadow.querySelector("#btnContinueShopping").addEventListener('click', () => {
      app.loadPage('product-list')
    })

    this.shadow.querySelector("#btnLogin").addEventListener('click', () => {
      app.login()
    })

    this.btnCheckout.disabled = true
    if (app.api.getToken()) {
      // we are logged in
      this.shadow.getElementById("login").style.display = "none";
    } else {
      this.shadow.getElementById("buy_actions").style.display = "none";
    }

    this.shadow.querySelector("#couponContainer").innerHTML = couponRedeem(this)
    let coupon = this.shadow.querySelector("#couponCode")
    if (coupon) coupon.addEventListener('change', async () => {
      if (app.cart.hasCoupon(coupon.value)) {
        alert("Coupon already applied.")
        coupon.value = ""
        return
      }
      coupon.disabled = true
      try {
        let ret = await app.api.getCoupon(coupon.value)
        coupon.value = ""
        let code = ret.code
        let calc = ret.conditions[0]
        let conditions = ret.conditions.splice(1).join(";")
        let message = "Coupon discounts " + calc + ". Conditions: " + conditions;
        app.confirm(message + ". Use this coupon?", async () => {
          app.cart.addCoupon(code, calc, conditions)
          this.update()
        })
      } finally {
        coupon.value = ""
        coupon.disabled = false
      }
    })

    this.update()
  }

  update() {
    this.shadow.querySelector("#cart_rows").innerHTML = cartLoading()
    this.main()
  }

  priceFormatted(amount) {
    return app.currency.currencySymbol() + " " + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  showNewOrgDialog() {
    let dialog = this.shadow.querySelector("#dlgNewOrg")
    dialog.disableOk()
    let newOrgCountry = dialog.querySelector("#newOrgCountry")
    let newOrgProvince = dialog.querySelector("#newOrgProvince")

    if (newOrgCountry.options.length == 0) {
      newOrgCountry.addEventListener('change', () => {
        // get provinces list
        this.removeOptions(newOrgProvince)
        let countryCode = newOrgCountry.options[newOrgCountry.selectedIndex].value
        app.api.getProvinces(countryCode).then((provinces) => {
          for (let province of provinces.provinces) {
            newOrgProvince.options.add(new Option(province.name))
          }
          dialog.enableOk()
        })
      })

      // get countries list
      app.api.getCountries().then((countries) => {
        for (let country of countries.countries) {
          newOrgCountry.options.add(new Option(country.name, country.isoCode))
        }
        newOrgCountry.dispatchEvent(new CustomEvent('change'))
      })

    } else {
      dialog.enableOk()
    }

    dialog.addEventListener('ok', () => {
      let selOrgType = dialog.querySelector("#newOrgType")
      let selCountry = dialog.querySelector("#newOrgCountry")
      let selProvince = dialog.querySelector("#newOrgProvince")
      let newOrgDetails = {
        name: dialog.querySelector("#newOrgName").value,
        organisationType: selOrgType.options[selOrgType.selectedIndex].value,
        registrationNumber: dialog.querySelector("#newOrgReg").value,
        taxNumber: dialog.querySelector("#newOrgTax").value,
        countryIsoCode: selCountry.options[selCountry.selectedIndex].value,
        provinceName: selProvince.options[selProvince?.selectedIndex]?.value,
        staffClassName: "Staff",
      }
      let form = dialog.querySelector("#frmNewOrg")
      if (!form.reportValidity()) return;
      this.checkout("new-org", newOrgDetails)
    })
    dialog.show()
  }

  showMyUnitDialog(buyOption, unitName, unitType) {
    let dialog = this.shadow.querySelector("#dlgMyUnit")
    dialog.disableOk()
    let newOrgCountry = dialog.querySelector("#newOrgCountry")
    let newOrgProvince = dialog.querySelector("#newOrgProvince")

    if (newOrgCountry.options.length == 0) {
      newOrgCountry.addEventListener('change', () => {
        // get provinces list
        this.removeOptions(newOrgProvince)
        let countryCode = newOrgCountry.options[newOrgCountry.selectedIndex].value
        app.api.getProvinces(countryCode).then((provinces) => {
          for (let province of provinces.provinces) {
            newOrgProvince.options.add(new Option(province.name))
          }
          dialog.enableOk()
        })
      })

      // get countries list
      app.api.getCountries().then((countries) => {
        for (let country of countries.countries) {
          newOrgCountry.options.add(new Option(country.name, country.isoCode))
        }
        newOrgCountry.dispatchEvent(new CustomEvent('change'))
      })
    } else {
      dialog.enableOk()
    }

    dialog.addEventListener('ok', async () => {
      let selCountry = dialog.querySelector("#newOrgCountry")
      let selProvince = dialog.querySelector("#newOrgProvince")
      this.checkout(buyOption, {
        name: unitName,
        organisationType: unitType,
        registrationNumber: "",
        taxNumber: "",
        countryIsoCode: selCountry.options[selCountry.selectedIndex].value,
        provinceName: selProvince.options[selProvince?.selectedIndex]?.value,
        staffClassName: "Purchasers",
        learnerClassName: "Learners",
      })
    })
    dialog.show()

  }

  isFamilyOrg(org) {
    return org && org.organisationType &&
      org.organisationType.toLowerCase() == "jumptrak community" ||
      org.organisationType.toLowerCase() == "unit" // for legacy reasons
  }

  async checkout(buyOption, newOrgDetails = {}) {
    if (!buyOption) {
        alert('Please select the organisation you are buying for. The cart will then re-calculate based on your selection.')
        return;
    }

    let products = []
    let rows = this.shadow.querySelectorAll("tr.product")
    for (let r of rows) {
      let qty = r.querySelector("input[name=quantity]")
      let price = r.querySelector("input[name=price]")
      if (qty.value > 0) {
        products.push({
          identifier: r.attributes["product_id"].value,
          description: r.querySelector(".productDescription").innerHTML,
          quantity: Number(qty.value),
          priceRuid: price.value,
          isAssignPurchaser: this.isForMe.checked,
          isTrial: r.attributes["is_trial"].value == "true"
        })
      }
    }

    if (products.length <= 0) {
      alert('You have not selected any products for purchase.')
      return;
    }

    // Get or create the organisation for this purchase
    let whoami = await app.api.whoAmI();
    let orgRuid = undefined
    if (buyOption == "new-org") {
      if (!newOrgDetails.name) {
        this.showNewOrgDialog()
        return;
      }
    } else if (buyOption == "myOwnUse" || buyOption == "me" || buyOption == "myFamily") {
      if (!newOrgDetails.name) {
        // create a new org for this family if it doesn't exist
        let orgName = whoami.party.fullName + " Family"
        // ask user which country they are in
        this.showMyUnitDialog(buyOption, orgName, "JumpTrak Community")
        return;
      }
    } else {
      orgRuid = buyOption
    }

    if (!orgRuid && !newOrgDetails.name) {
      alert("Unable to get organisation for this purchase.")
      return;
    }

    let organisationName = (orgRuid ? this.buyOrganisation.organisation.name : newOrgDetails.name)
    await new Promise((res, rej) => {
      app.confirm("Click OK to confirm placing the order for organisation: " + organisationName, async () => {
        await this.createOrder(orgRuid, whoami, products, buyOption, newOrgDetails)
        res()
      }, () => res())
    })
  }

  async createOrder(orgRuid, whoami, products, buyOption, newOrgDetails = {}) {
    if (this.orderBusy) {
      console.error(new Error("ORDER RECURSION!"))
      return;
    }
    let doc = app.api.getEnv().DOC_PREFIX + new Date().getTime();
    let organisation = (orgRuid ? { ruid: orgRuid } : { ...newOrgDetails })
    let purchaser = {
      ruid: whoami.person.ruid, // not strictly needed since backend will use logged-in user
      organisation
    }
    let learners = []

    if (purchaser.organisation.ruid) {
      // Let's check purchase permissions for existing org by trying to buy a "dummy" product
      let checkPerms = await app.api.apxBuyPreflight(purchaser)
      if (!checkPerms.success) {
        if (checkPerms.message.indexOf("BRL493I ") >= 0) {
          alert("BRL493I You are not a valid purchaser/representative for the given organisation, please contact the Finance admin for this purchase.");
          return;
        } else if (checkPerms.message.indexOf("BRM053I ") >= 0) {
          alert("BRM053I You are not a valid purchaser/representative for the given organisation, please contact the Finance admin for this purchase.");
          return;
        } else {
          alert(checkPerms.message);
          return;
        }
      }
    }

    let total_price = this.shadow.getElementById("cart_total_price").totalPrice
    this.order = {
      doc, purchaser, products, learners, total_price
    }

    this.orderBusy = true;
    let buyRequest = await app.api.apxBuyRequest(doc, purchaser, products, app.cart.getCouponCodes())
    let ret = await app.api.apxBuy(buyRequest)
    this.orderBusy = false;
    if (!ret.task) {
      alert(ret.message)
      return;
    }

    let final_price = JSON.parse(ret.task.packet).amount
    if (final_price != total_price) {
      let message = `The total price for the order may have changed.\n
        Original price: ${total_price}\n
        Current price: ${final_price} \n
        Continue processing order with the current price?`
      app.confirm(message, async () => {
        await this.processOrder(doc, ret, final_price)
      }, () => {
        app.toast('Order paused, you can continue the order on the "My Orders" page.')
        app.loadPage("orders")
      })
    } else {
      await this.processOrder(doc, ret, final_price)
    }
    return false;
  }

  async processOrder(doc, ret, final_price) {
    // clear the cart as the order has been created
    app.cart.clear()
    app.setOrderDetails(ret)
    app.loadPage('order-created')
  }

  onCurrency(currencyCode) {
    this.update()
  }

  async main() {
    const products = app.cart.getProducts()
    if (products == undefined || products.length == 0) {
      this.shadow.querySelector("#cart_rows").innerHTML = `<tr><td cellspan="3">Your cart is empty.</td></tr>`
      this.shadow.querySelector("#buy_actions").style.display = "none"
      this.shadow.getElementById("cart_total_price").innerHTML = ""
      return;
    }
      
    this.shadow.querySelector("#cart_rows").innerHTML = cartLoading(this)
    
    // get updated prices for the products
    let productPrices = await app.api.getProductCapabilityPrices(
      products.map(pc => pc.id), products.map(pc => pc.quantity),
      app.getBuyOrganisation(), app.currency.currencyCode(), app.cart.getCouponCodes())

    let rows = ""
    let total_price = 0
    for (let p of products) {
      // update price
      try {
        p.price = productPrices.productCapabilities.filter((pp) => pp.id == p.id)[0].price
        if (p.price != undefined && p.quantity > 0) {
          if (p.isTrial) {
            p.price.amount = 0
          }
          rows += cartRow(this, p)
        }
      } catch (e) {
        // if there are errors (e.g. product not longer exists), remove from cart
        console.log(e)
      }
    }

    this.shadow.getElementById("cart_rows").innerHTML = rows;
    let coupons = productPrices.coupons.map(c => couponRow(this, c)).join("")
    this.shadow.querySelector("#cart_rows").innerHTML += coupons

    const calcCart = (enableCheckout = true) => {
      total_price = 0;
      let rows = this.shadow.querySelectorAll("#cart_rows tr")
      for (let row of rows) {
        let qty = row.querySelector("input[name=quantity]")
        let price = row.querySelector("input[name=price]")
        let lineTotal = (Number(price.getAttribute('amount')) * Number(qty.value))
        row.querySelector("td#line_total").innerHTML = this.priceFormatted(lineTotal)
        total_price += lineTotal
      }
      if (total_price < 0) total_price = 0

      this.shadow.getElementById("cart_total_price").innerHTML = this.priceFormatted(total_price);
      this.shadow.getElementById("cart_total_price").totalPrice = total_price;

      if (enableCheckout && this.buyOrganisation.value !== undefined) this.btnCheckout.disabled = false;
    }
    calcCart()

    this.updatePriceTimeout = 0
    requestAnimationFrame(() => { // wait for above to render
      let rows = this.shadow.querySelectorAll("#cart_rows tr")
      for (let row of rows) {
        let qty = row.querySelector("input[name=quantity]")
        qty.addEventListener('change', () => {
          this.btnCheckout.disabled = true
          app.cart.updateQuantity(qty.id, qty.value)
          calcCart(false)
          if (this.updatePriceTimeout) clearTimeout(this.updatePriceTimeout)
          this.updatePriceTimeout = setTimeout(() => this.update(), 2000)
        })
      }

      for (let btn of this.shadow.querySelectorAll("ion-button.delete")) {
        btn.addEventListener('click', () => {
          if (btn.attributes['couponCode']) {
            app.cart.removeCoupon(btn.attributes['couponCode'].value)
          } else {
            app.cart.removeProduct(btn.id)
          }
          this.update()
        })
      }

    })
  }
}) 
